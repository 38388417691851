.breadcrumbs-container {
  padding-top: 2em;
  padding-left: 10em;
}

.breadcrumb-inactive-stepper {
  font-family: "EYInterstate", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #F9F9FA !important;
}

.stepper-section-content {
  background-color: #ffffff;
}

.breadcrumb-active-stepper {
  font-family: "EYInterstate", sans-serif;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #C3C3CB !important;
}

.custom-stepper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "EYInterstate", sans-serif;
}

.stepper-background {
  background-color: #2e2e38;
  width: 100%;
}

.stepper-box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 10px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-bottom: 1em;
  flex: 1 0 100px;
  transition: background-color 0.3s ease; /* Add transition for smooth effect */
}

.stepper-box-item:hover .stepper-content {
  background-color: #4d4d5c; /* Light grey background on hover */
}

.stepper-content {
  display: flex;
  align-items: center;
  border: 1px solid #4d4d5c;
  background-color: #2e2e38;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap; /* Prevent text from wrapping */
}

.stepper-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 20px; /* Set width for the icon */
  height: 20px; /* Set height for the icon */
}

.stepper-text {
  font-size: 14px;
  color: #d7d7dc;
  font-family: "EYInterstate", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.line {
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #4d4d5c;
  left: calc(100% + 0px);
  top: 50%;
  transform: translateY(-1px);
}

.stepper-box-item.active .stepper-content {
  background-color: #ffe600;
}

.custom-stepper .stepper-box-item.active .stepper-icon,
.custom-stepper .stepper-box-item.active .stepper-text {
  color: #2e2e38;
}

@media (min-width: 600px) {
  .custom-stepper {
    padding: 2.25em;
  }

  .stepper-box-item {
    flex: 1;
    margin-right: 15px;
  }

  .line {
    width: 45px;
  }
}

@media (min-width: 768px) {
  .custom-stepper {
    padding: 3em;
  }

  .stepper-box-item {
    margin-right: 20px;
  }

  .line {
    width: 60px;
  }
}

@media (min-width: 1024px) {
  .custom-stepper {
    padding: 2em 10em;
  }

  .stepper-box-item {
    margin-right: 20px;
  }

  .line {
    width: 50px;
  }
}

@media (max-width: 1024px) {
  .custom-stepper {
    flex-wrap: wrap;
  }

  .stepper-box-item {
    flex: 1 0 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .line {
    display: none;
  }
}