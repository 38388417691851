.iro-assessment-container {
  background-color: #f3f3f5;
}

.assessment-card {
  width: 100%;
  border: 1px solid rgba(230, 230, 233, 1);
  border-radius: 8px;
  margin-bottom: 20px;
  overflow-y: auto;
}

.sm-card {
  width: 100%;
  border: 1px solid rgba(230, 230, 233, 1);
  border-radius: 8px;
  height: 35vh;
  margin-bottom: 8px;
}

.sm-card --card--border-style {
  padding: none !important;
}

.motif-card-body-scrollable {
  max-height: 400px; // Adjust the height as needed
  overflow-y: auto;
  overflow-x: hidden;
}

.motif-card-body-scrollable .tree-item {
  overflow: hidden;
}

.motif-card-body-scrollable .labelText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 32%;
}

.assessment-header {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  background: rgba(46, 46, 56, 1) !important;
  color: white !important;
  padding: 16px !important;
  border-radius: 8px 8px 0 0 !important;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.assessment-table .table-container {
  overflow: auto !important;
}

.iro-section {
  margin-top: 40px;
  padding: 0% 12%;
  overflow-x: scroll !important;

  @media (max-width: 768px) {
    padding: 1.1428571429rem !important;
  }
}

.iro-right-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 480px) {
    align-items: flex-start;
  }
}

.iro-section .motif-row {
  flex-wrap: nowrap !important;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.iro-assessment-container .motif-card-border {
  padding: 0 !important;
}

.assessment-card.motif-card-orientation-vertical {
  height: 51.5rem;
}

.assessment-table .table-container .table-wrapper {
  max-height: 100% !important;
}