.info-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.info-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    user-select: text;
    cursor: default;
}

.info-modal-header {
    position: relative;
}

.info-modal-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.kba-code {
    font-size: 14px;
    color: #333;
}

.info-modal-lang {
    display: flex;
    align-items: center;
}

.language-code {
    margin-right: 5px;
    font-size: 14px;
    color: #333;
}

.info-modal-title {
    font-weight: bold;
}

.info-modal-content {
    overflow-y: auto;
    line-height: 1;
    user-select: text;
    cursor: default;
}

.info-cancel-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: -14px;
    top: -14px;
}

.globe-icon {
    border: none !important;
    background: none !important;
}

.globe-icon svg {
    margin-top: 12px;
}

.info-modal-content h1,
.info-modal-title,
.info-modal-content h2,
.info-modal-content p {
    text-align: left;
}

.info-modal-content p {
    white-space: normal;
}

.info-modal-content .formatted-description {
    max-height: 500px !important;
}