.iro-assessment-subheader-container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding-left: 0.2%;
}
.dga-button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  color: #2e2e38;
  background: #ffffff;
  margin-right: 0.2%;
  height: 3.15em;
  margin-top: 1.5%;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: rgb(230, 230, 233) !important;
    color: #33333e !important;
  }
}

.select-box {
  width: 200px;
  background: #ffffff;
  margin-left: 10px;
  margin-top: 10px;
}
.iro-assessment-subheader-section {
  background-color: #ffffff;
  width: 76%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 468px) {
    flex-direction: column;
    padding: 20px;
  }
}

.iro-assessment-subheader-section1 {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0px;
}

.iro-assessment-subheader-section2 {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 468px) {
    margin-top: 30px;
    margin-left: 20px;
  }
}

.iro-assessment-subheader-body {
  margin-left: 30px;
}

.iro-left-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #f3f3f5;
  border: none !important;
  cursor: pointer;
  &:hover {
    background-color: rgb(230, 230, 233) !important;
    color: #33333e !important;
  }
}

.iro-assessment-name-section {
  display: flex;

  @media (max-width: 468px) {
    flex-direction: column;
  }
}
.iro-impact {
  background-color: #fcd9bc !important;
  width: fit-content;
  padding: 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin-top: 1.25em;
  margin-left: 1em;
}

.iro-impact-label {
  font-family: EYInterstate, sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
  color: #975100 !important;
}

.iro-dga-assessment-subheader-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  margin: 0% 13.34% 0% 13.54%;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0% 5% 0 5%;
  }

  @media (max-width: 468px) {
    padding: 0% 2% 0 2%;
  }
}
.iro-assessment-sub-name {
  margin: 0px;
}

.next-previous-btn {
  font-size: 1.11rem;
  width: 7em;
}

.select-dga-input .motif-select-input-text {
  background: #ffffff;
  color: #2e2e38 !important;
  font-weight: 400 !important;
}

.select-dga-input .motif-select-input {
  border-color: #2e2e38 !important;
}
.iro-dga-assessment-question-subheader-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  margin: 0% 13.85% 0% 14%;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0% 5% 0 5%;
  }

  @media (max-width: 468px) {
    padding: 0% 2% 0 2%;
  }
}

.iro-dga-assessment-subheader-section {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1% 1% 0% 0%;

  @media (max-width: 468px) {
    flex-direction: column;
    padding: 20px;
  }
}

.iro-dga-assessment-name-section {
  margin-bottom: 4px;
}
.motif-h6-default-regular {
  margin-bottom: 0;
}

.motif-body2-default-light {
  margin-top: 0;
}

.iro-dga-assessment-subheader-section1 {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0px;
  margin-right: 20px;
}

.iro-dga-assessment-subheader-section2 {
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-left: 10px;
  }

  @media (max-width: 468px) {
    margin-top: 30px;
    margin-left: 0;
  }
}

.iro-dga-assessment-subheader-body {
  margin-left: 10px;

  @media (max-width: 768px) {
    margin-left: 10px;
  }

  @media (max-width: 468px) {
    margin-left: 0;
  }
}
