.mapping-card {
  width: 100%;
  border: 1px solid rgba(230, 230, 233, 1);
  border-radius: 8px;
}

.mapping-header {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background: rgba(46, 46, 56, 1);
  color: white;
  padding: 16px;
  border-radius: 8px 8px 0 0;
}

.motif-card-body {
  padding: 20px;
}
.mapping-card-dga-body {
  padding: 10px !important;
}
.mapping-card .form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.mapping-card .form-group {
  flex: 1;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &.full-width {
    flex: 0 0 100%;
    margin-right: 0;
  }
}

.mapping-card .form-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 1em;
}

.mapping-card .button {
  margin-left: 10px;
}

.mapping-card .cancel-button {
  background-color: #f0f0f0;
}

.mapping-card .save-button {
  background-color: #000;
  color: #fff;
}

.session-modal-input-dga {
  width: 100%;
}

.mapping-card-dga {
  width: 100%;
  gap: 0 !important;
}

.mapping-card-dga .form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.mapping-card-dga .form-group {
  display: flex;
  flex-direction: row;
  gap: 3em;

  &:last-child {
    margin-right: 0;
  }

  &.full-width {
    flex: 0 0 100%;
    margin-right: 0;
  }
}
.dga-racl-message {
  min-width: 5em;
  margin-top: 9px !important;
}

.mapping-card-dga .form-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 1em;
}

.mapping-card-dga .button {
  margin-left: 10px;
}

.mapping-card-dga .cancel-button {
  background-color: #f0f0f0;
}

.mapping-card-dga .save-button {
  background-color: #000;
  color: #fff;
}
