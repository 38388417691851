.context-stepper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 1em 2em;
  font-family: "EYInterstate", sans-serif;
  overflow-x: auto;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}

.context-background {
  background-color: #f3f3f5;
  width: 100%;
}

.context-box-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #f3f3f5;
  border: none;
  padding: 10px 20px !important;
  margin: 0.5em 0.5em 0.5em 0;
  transition: background-color 0.3s;
  height: auto;
  white-space: nowrap;
  cursor: pointer;
  flex: 0 0 auto;
}

.context-content {
  display: flex;
  align-items: center;
}

.context-text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 45px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2e2e38;
  font-family: "EYInterstate", sans-serif;
  gap: 6px;
}

.context-box-item.active {
  background-color: #ffffff;
  padding: 15px 25px;
}

.context-box-item:focus,
.context-box-item:active {
  background-color: #ffffff;
  outline: none;
}

.context-section-content {
  margin-top: 1em;
  padding: 0 2em;
}

@media (min-width: 768px) {
  .context-stepper {
    padding: 1em 5em;
  }

  .context-box-item {
    margin: 0;
  }

  .context-section-content {
    padding: 0 5em;
  }
}

@media (min-width: 1024px) {
  .context-stepper {
    padding: 0 11em;
  }

  .context-section-content {
    padding: 0 10em;
  }
}

.context-icon {
  margin-top: 0.7em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-placeholder {
  background-color: transparent;
  border: none;
}
