.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: thin solid #e8e8e8;
  /* Corrected the border color syntax */
}

.close-button {
  background: none;
  border: none;
  font-size: 2.2rem;
  cursor: pointer;
}

.modal-body {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.form-group {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  flex-basis: 50%;
  max-width: 50%;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group-email {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  flex-basis: 100%;
  max-width: 100%;
}

.user-modal-input {
  border-width: thin !important;
  border-color: #ccc !important;
  border-style: solid !important;
}

.user-modal-input .motif-select-input {
  border-style: none !important;
}

.email-modal-input .motif-select-input {
  border-style: none !important;
  width: 100%;
}

.new-project-footer {
  display: flex;
  justify-content: flex-end;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.cancel-button {
  background: #f3f3f5;
  margin-right: 10px;
}

.cancel-button:hover,
.close-button:hover {
  background-color: rgb(230, 230, 233) !important;
  color: #33333e !important;
}

.done-button {
  background: #2e2e38;
  color: #fff;
  font-weight: 400;
}

.done-button:hover {
  background-color: #2e2e38;
}

.done-button .back-button-icon svg {
  color: #fff !important;
}

.delete-button {
  margin-left: 5px;
  background: #e91109;
  color: #fff;
  font-weight: 400;
}

// .new-project-card {
//   width: 100% !important;
//   top: 20%;
//   margin-top: 5%;
//   margin-bottom: 5%;
//   padding: 25px;
//   border-color: #fff;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
// }

.full-width {
  flex-basis: 100% !important;
  max-width: 100% !important;
}

.modal-footer {
  float: right;
  display: flex;
  gap: 1em;
}

.motif-message {
  margin-bottom: 10px;
}

.clone-module-form-row {
  display: block !important;
  margin: 0px !important;
}

.clone-module-form-row .form-group {
  max-width: 100% !important;
  padding: 0px !important;
}

.modal.clone-module-modal {
  width: 30% !important;
}

.dma-import-group {
  display: flex;
  flex-direction: column;
}

.dma-import-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dma-import-dropdown {
  flex: 1;
  /* Ensures the dropdown takes up remaining space */
}

.import-button {
  white-space: nowrap;
  /* Prevents button text from wrapping */
  padding: 8px 16px;
  height: 100%;
}

.toggles-inline {
  display: flex;
  gap: 1rem;

  .form-group {
    flex-basis: auto !important;
    max-width: none !important;
  }
}

.row-label-with-icon {
  display: flex;
  align-items: center;
  gap: 4px;

  .kba-info-icon-button {
    transform: translateY(4px);
  }
}