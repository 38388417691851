.comment-toggle-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 400px;
  padding: 5% 5% 2% 5%;
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;
  overflow-y: auto;
}

.DGA-Label {
  font-size: 16px !important;
  color: #000000;
}

.material-text {
  font-weight: 500;
  white-space: nowrap;
}

.comment-toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-toggle-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.comment-toggle-right {
  align-items: center;
}

@media (max-width: 600px) {
  .comment-toggle-container {
    width: 100%;
  }

  .comment-toggle-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .comment-toggle-actions {
    flex-direction: column;
    align-items: stretch;
  }
}
.dga-toggle {
  display: inline;
  width: 4em;
  padding-left: 1%;
}
.dga-toggle .motif-toggle-switch input:checked + .motif-toggle-switch-slider {
  background-color: #FFE600 !important;
}
