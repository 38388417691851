.module {
  float: right;
  right: 55px;
  position: absolute;
  display: flex;
  justify-content: right;
  align-items: center;
}

.accordion-item {
  margin-bottom: 1rem;
  padding-bottom: 1.5%;
}

.accordion-item > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.session-table-data {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "EYInterstate", sans-serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 24px !important;
}

.session-accordion-icons {
  float: right;
  right: 2px;
  position: absolute;
  display: flex;
  justify-content: right;
  margin-top: -2.5%;
  margin-bottom: 20%;
}

.module-link {
  color: #0f69ae;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  border: none !important;
  background: none;
  font-family: "EYInterstate", sans-serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;
  cursor: pointer;
  padding-bottom: 10px;
}

.icon-container-session {
  display: flex;
  margin-bottom: 50px;
}

.trigger-content .module {
  margin-left: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .accordion-item > div {
    flex-direction: column;
    align-items: flex-start;
  }

  .session-accordion-icons {
    position: static;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .module {
    right: 0;
    position: static;
    margin-top: 10px;
  }

  .module-link {
    font-size: 14px !important;
  }

  .session-table-data {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

@media (max-width: 480px) {
  .session-table-data {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  .module-link {
    font-size: 12px !important;
  }

  .session-accordion-icons {
    margin-top: 0;
    margin-bottom: 0;
    align-items: center;
  }

  .icon-container-session {
    margin-bottom: 0;
  }
}

.module-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  margin: 20px auto;

  .module-card,
  .add-module {
    width: 253px;
    height: 276px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .module-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .module-type-name {
      h4 {
        font-size: 14px;
        font-weight: 600;
        color: #333;
        margin: 0;
      }
    }

    .client-ready {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: #197335;

      .green-dot {
        width: 8px;
        height: 8px;
        background-color: #197335;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }

  /* Icon container */
  .module-icon-container {
    background-color: #f9f9fa;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin: 10px 0;

    .module-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-placeholder {
        font-size: 36px;
        color: #888;
      }
    }
  }

  /* Module name styling */
  .module-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 1rem !important;
    margin: 0;
  }

  .module-name .module-name-truncate,
  .module-type-name .module-type-truncate,
  .module-type-name .module-type-truncate .module-type-heading {
    line-height: 1.2rem !important;
  }

  /* Card actions (buttons) */
  .module-card-actions {
    display: flex;
    justify-content: flex-end;
  }

  /* Add Module Card */
  .add-module {
    border: 2px dashed #afcdfb;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;

    &:hover {
      background-color: #e7f0ff;
      border-color: #005bb5;
    }

    .module-link {
      font-size: 16px;
      font-weight: 600;
      color: #0073e6;
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  /* Responsive adjustments */
  @media (max-width: 1200px) {
    justify-content: flex-start;
    /* Align cards to left on smaller screens */
  }

  @media (max-width: 768px) {
    gap: 15px;
    /* Reduce gap for smaller screens */
    justify-content: center;
    /* Center align cards */
  }

  @media (max-width: 480px) {
    gap: 10px;
    /* Further reduce gap */
    justify-content: center;
    /* Center cards for very small screens */
    margin: 10px auto;
  }
}
