.table-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.table-header-cell {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important;
  background-color: #f3f3f5;
  color: #2e2e38 !important;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 6px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 4px;
  }
}

.table-content .MuiTableCell-root {
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 20px !important;
  padding: 12px 20px;
  padding-left: 28px !important;
  border-bottom: 1px solid #e6e6e9;
  word-wrap: break-word;
  max-width: 200px;
}

.iro-library-table-content.table-content .MuiTableCell-root:last-child {
  text-align: left;
  padding-right: 4.12%;
}

.table-content .MuiTableCell-root:first-child {
  padding-left: 30px !important;
}

.table-content .MuiTableRow-root {
  transition: background-color 0.3s ease;
}

.table-content .MuiTableRow-root:hover {
  background-color: #f5f5f5;
}

.table-wrapper {
  max-height: 50vh; 
  overflow-y: auto;
}

.sort-icon {
  padding: 0 5px;
  display: inline-block;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }

  @media (max-width: 768px) {
    padding: 0 2px;
  }

  @media (max-width: 480px) {
    padding: 0 1px;
  }
}

@media (max-width: 768px) {
  .table-container {
    overflow-x: auto;
  }

  .table-header-cell,
  .table-body-cell {
    white-space: nowrap;
  }
}

@media (max-width: 480px) {
  .table-header-cell,
  .table-body-cell {
    white-space: nowrap;
    font-size: 12px;
  }
}

.loading-indicator  {
  text-align: center;
}
