.iro-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.iro-modal {
  background: #fff;
  padding: 0px !important;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80% !important;
  max-width: 50%;
}

.iro-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px !important;
  border-bottom: 1px solid #e6e6e9 !important;
  border-color: E8E8E8;
  border-width: thin;
  padding-left: 45px;
}

.iro-modal-body {
  display: flex;
  justify-content: flex-start;
}

.iro-form-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.iro-modal-footer {
  float: right;
  display: flex;
  padding-right: 58px;
  margin-top: 20px;
  gap: 1em;
}

.iro-label {
  flex: 0 0 18%; /* 30% width for label */
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: "EYInterstate", sans-serif;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #656579;
}

.iro-textField {
  flex: 1; /* Remaining 70% for textbox */
  padding: 0px;
  font-size: 1rem;
  width: 100%;
}

.iro-modal .modal-content {
  flex: 1;
  padding-top: 25px;
  margin-bottom: 40px;
}

.iro-modal-sidebar {
  width: 157px;
  border-right: 1px solid #ddd;
  padding-top: 25px;
}

.iro-modal-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.iro-modal-sidebar li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.iro-modal-sidebar button {
  width: 100%;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  transition: background-color 0.2s;
  padding-left: 30px;
}

.iro-modal-sidebar button:hover {
  background-color: #f5f5f5;
}

.iro-modal-sidebar button.active {
  background-color: #2e2e38;
  color: #ffffff;
  font-weight: 400;
}

.info-icon {
  position: absolute;
  z-index: 100;
  right: 3em;
  padding-top: 8px;
  cursor: pointer;
}

.info-description {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 7%;
  right: -290px;
  background-color: #f3f3f5;
  border-radius: 8px;
  padding: 10px;
  z-index: 1000;
  margin-top: 10px;
  font-family: "EYInterstate", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #2e2e38;
}

.iro-textField .motif-select-input {
  padding: 0 0.6428571429rem 0.2857142857rem;
}

.iro-textField .motif-select-wrapper-options {
  transform: translate3d(0px, 55px, 0px) !important;
}

.error-message-iro-modal {
  margin-left: 176px;

  @media (max-width: 468px) {
    margin-left: 10px;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .iro-modal {
    width: 90%;
    margin: auto;
    padding: 20px;
  }
  .iro-modal-body {
    display: flex;
    flex-direction: column;
  }
  .iro-modal-sidebar {
    width: 100%;
    order: 1;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .iro-modal-header h2 {
    font-size: 1.2rem;
  }
  .iro-modal-footer {
    flex-direction: row;
    align-items: center;
    .button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .filter-container .motif-label {
    display: none;
  }
  .filter-container .filter-by-select {
    width: 100%;
  }
}

.sidebar-item:disabled {
  background-color: #f0f0f0;
  color: #ccc;
  cursor: not-allowed;
}

.sidebar-item:disabled:hover {
  background-color: #f0f0f0;
}

.RatingError{
  padding-left: 180px;
  margin-top: 4px;
  min-height: 18px;
}

