.chart-container {
  flex: 1;
  min-width: 0;
  padding: 10px;
  width: 100%;
  height: 650px;
}

.big-button {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px;
}

.border-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.big-button:hover {
  background-color: #0056b3;
}

.dma-reporting-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-left: 8%;
  padding-right: 9%;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.vertical-line {
  width: 1px;
  background-color: #000;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .dma-reporting-row {
    flex-direction: column; 
    padding-left: 0;
    padding-right: 0;
  }

  .vertical-line {
    display: none;
  }

  .chart-container {
    width: 100%;
    padding: 0;
  }
}

.reporting-button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  gap: 9px;
  padding: 3% 8.5% 2% 7.5%;
}

.reporting-button-container button {
  padding: 10px 15px;
  font-size: 18px;
  width: 21em;
  height: 60px;
  background-color: #ffe600;
  color: #2e2e38;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.reporting-button-container button:hover {
  background-color: rgb(230, 230, 233) !important;
  color: #33333e !important;
}

@media (min-width: 768px) {
  .reporting-button-container button {
    width: 21em;
  }
}

.chart-title {
  margin: 0%;
  padding: 0%;
}
