.sm-file-explorer {
    padding: 2em 4.25em;
    height: 80vh;
    overflow-y: auto ;
  
    @media (max-width: 1200px) {
      padding: 2em 2em 2em 2.25em;
    }
  
    @media (max-width: 992px) {
      padding: 2em 1em 2em 6em;
    }
  
    @media (max-width: 768px) {
      padding: 2em 4em;
    }
  
    @media (max-width: 576px) {
      padding: 2em 2em;
    }
  
    @media (max-width: 400px) {
      padding: 1em 1em;
    }
  }
  